/** Skater **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data;

class IntroPage4 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[4];

    //refs
    this.skaterVideoRef = this.planeLandingVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.skaterVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
    positionText(this.refs.captionRef, "right", "top", globalState.textPosition.rightTop);
    positionText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0, delay: 2 });
    gsap.to(this.refs.text2Ref, 0.15, { autoAlpha: 1.0, delay: 2 });
    gsap.to(this.refs.captionRef, 0.15, { autoAlpha: 1.0, delay: 2 });

    //If coming to this chapter by clicking prev
    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);  //book basetrack (loop)
      globalState.baseAudioRef.updateTrack(2, false);  //intro basetrack (remove)
    }

    //Preload assets from next page
    preloadImages([globalState.cdnUrl + data.nextTitle], this.preloadCallback);


  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentWillUnmount() {
    this.skaterVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    gsap.killTweensOf(this.refs.text2Ref);

    
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
      showText(this.refs.text2Ref, "right", "bottom", globalState.textPosition.rightBottom);
      showText(this.refs.captionRef, "right", "top", globalState.textPosition.rightTop);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
      hideText(this.refs.text2Ref, "right");
      hideText(this.refs.captionRef, "right");
    }
  }

  isTransitioning = () => {
    this.skaterVideoRef.pauseVideo();
  }



  render() {

    return (

      <>

        <link rel="prefetch" href="/town" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/town" prevLocation="/intro/3"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Intro" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="THE MINE CLOSED IN 1987. Most industry towns, after losing their purpose, attempted resurrections, reinventions, or just slowly wither away. In Pine Point, they decided to erase the town from the face of the earth. By the following year, almost everything had been hauled away, buried, or burned. Even the arena where they held their winter carnivals – home to my last little flickering memory. Not replaced or refurbished – just gone, as though it never was." />
            </div>
            <div className="text text--right-top" ref="captionRef">
              <input type="image" src={globalState.cdnUrl + data.caption} style={{ marginRight: "50px", marginTop: "10px" }} onClick={this.toggleTextDisplay} alt="Most Pine Pointers think their hometown was the best place on earth to have lived." />
            </div>
            <div className="text text--right-bottom" ref="text2Ref">
              <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginRight: "50px", marginBottom: "300px" }} onClick={this.toggleTextDisplay} alt="Final winter carnival, Pine Point Arena, 1987" />
            </div>
          </div>

          {data.skaterVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.skaterVideo}
              poster={globalState.cdnUrl + data.skaterVideoPoster} autoPlay={false} loop={true} ref={div => this.skaterVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }
        </div>
        )}
      </>
    );
  }


};

export default IntroPage4
